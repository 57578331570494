import { IEvenement } from '@/models/evenement';
import { ISubscription } from '@/models/subscription';
import axiosService, { isCallSucceeded } from '@/tools/axios-wrapper';
import { InscriptionRdvMafDto } from '@/data-contracts/inscription-rdv-maf-dto';

export interface IEvenementFilter {
    numeroPage?: number;
    taillePage?: number;
    filtreStr?: string | null;
    typeEvenementIds?: number[];
    statusPublicationIds?: number[];
    intervenantIds?: number[];
    dureeMax?: number | null;
    dateDebut?: Date | null;
    dateFin?: Date | null;
    sortDescending?: boolean;
}

export interface IPaginatedEvenement {
    pageNumber: number;
    totalPageNumber: number;
    result: IEvenement[];
}

export interface ISubscriptionResult {
    success: boolean;
    inscrits: ISubscription[];
}

function prepareFilterQueryParameter(filters: IEvenementFilter): any {
    const queryParams: any = {};
    Object.entries(filters).forEach((tuple) => {
        if (!tuple[0].startsWith('date')) {
            queryParams[tuple[0]] = tuple[1];
        } else if (tuple[1]) {
            const date = tuple[1] as Date;
            queryParams[tuple[0]] = date.getDate();
            queryParams[`mois${tuple[0].substring(4)}`] = date.getMonth() + 1;
            queryParams[`annee${tuple[0].substring(4)}`] = date.getFullYear();
        }
    });
    return queryParams;
}

class EvenementService {
    private readonly url: string;
    constructor(url: string = 'api/evenement') {
        this.url = url;
    }

    public async getAll(): Promise<IEvenement[]> {
        const apiResponse = await axiosService.get<IPaginatedEvenement>(this.url);
        if (apiResponse && isCallSucceeded(apiResponse.status)) {
            return apiResponse.data?.result ?? [];
        }
        return [];
    }

    public async getById(id: number): Promise<IEvenement | null> {
        const apiResponse = await axiosService.get<IEvenement>(`${this.url}/{id}`, null, {
            id: `${id}`
        });
        if (apiResponse && isCallSucceeded(apiResponse.status)) {
            return apiResponse.data ?? null;
        }
        return null;
    }

    public async create(payload: IEvenement): Promise<number | null> {
        const apiResponse = await axiosService.post<number, IEvenement>(this.url, payload);
        if (apiResponse && isCallSucceeded(apiResponse.status)) {
            return apiResponse.data ?? null;
        }
        return null;
    }

    public async update(id: string, payload: IEvenement): Promise<boolean> {
        const apiResponse = await axiosService.patch<boolean, IEvenement>(`${this.url}/{id}`, payload, null, {
            id: id
        });
        if (apiResponse) {
            return isCallSucceeded(apiResponse.status);
        }
        return false;
    }

    public async replace(id: string, payload: IEvenement): Promise<boolean> {
        const apiResponse = await axiosService.put<boolean, IEvenement>(`${this.url}/{id}`, payload, null, {
            id: id
        });
        if (apiResponse) {
            return isCallSucceeded(apiResponse.status);
        }
        return false;
    }

    public async remove(id: string): Promise<boolean> {
        const apiResponse = await axiosService.delete<boolean>(`${this.url}/{id}`, null, {
            id: id
        });
        if (apiResponse) {
            return isCallSucceeded(apiResponse.status);
        }
        return false;
    }

    public async getByFilter(filters: IEvenementFilter): Promise<IPaginatedEvenement | null> {
        const queryParams = prepareFilterQueryParameter(filters);
        const apiResponse = await axiosService.get<IPaginatedEvenement>(`${this.url}`, queryParams);
        if (apiResponse && isCallSucceeded(apiResponse.status)) {
            return apiResponse.data ?? null;
        }
        return null;
    }

    public async subscribe(evenementId: number, subscriptions: ISubscription[]): Promise<ISubscriptionResult | null> {
        const apiResponse = await axiosService.post<ISubscriptionResult, ISubscription[]>(`${this.url}/{id}/inscrire`, subscriptions, null, {
            id: `${evenementId}`
        });
        return apiResponse && isCallSucceeded(apiResponse.status) ? apiResponse.data ?? null : null;
    }

    public async unsubscribe(evenementId: number): Promise<boolean> {
        const apiResponse = await axiosService.post<boolean>(`${this.url}/{id}/desinscrire`,null, null, {
            id: `${evenementId}`
        });
        return apiResponse && isCallSucceeded(apiResponse.status);
    }

    public async subscribePresentiel(evenementId: number, subscriptionDto: InscriptionRdvMafDto): Promise<any> {
        const apiResponse = await axiosService.post<any, InscriptionRdvMafDto>(`${this.url}/{id}/presentiel/inscrire`, subscriptionDto, null, {
            id: `${evenementId}`
        });
        return apiResponse && isCallSucceeded(apiResponse.status) ? apiResponse.data ?? null : null;
    }

    public async inviterPresentiel(evenementId: number, collaborateurs: string[]): Promise<any> {
        const apiResponse = await axiosService.post<any, string[]>(`${this.url}/{id}/presentiel/inviter`, collaborateurs, null, {
            id: `${evenementId}`
        });
        return apiResponse && isCallSucceeded(apiResponse.status) ? apiResponse.data ?? null : null;
    }
}

const evenementService = new EvenementService();
export default evenementService;
