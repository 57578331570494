import Vue from 'vue';
import Vuex from 'vuex';
import { extractVuexModule, createProxy } from 'vuex-class-component';
import { AppStore } from './app-store';
import { EvenementStore } from './evenement-store';
import { InscriptionRdvStore } from './inscription-rdv-store';
import { ReferentialStore } from './referential-stores';
import { SubscriptionsStore } from './subscription-store';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        ...extractVuexModule(AppStore),
        ...extractVuexModule(ReferentialStore),
        ...extractVuexModule(EvenementStore),
        ...extractVuexModule(SubscriptionsStore),
        ...extractVuexModule(InscriptionRdvStore)
    }
});

const proxyStore = {
    app: createProxy(store, AppStore),
    referentials: createProxy(store, ReferentialStore),
    evenements: createProxy(store, EvenementStore),
    subscriptions: createProxy(store, SubscriptionsStore),
    inscriptionRdv: createProxy(store, InscriptionRdvStore)
};

proxyStore.referentials.initStore();
proxyStore.app.fetchUserProfile().then(() => {
    proxyStore.subscriptions.fetchUserSubscriptions();
});
proxyStore.app.fetchNavigation();

export const vxm = proxyStore;
