<template>
    <div v-show="show">
        <div class="d-flex justify-content-center"  v-if="loading">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="collaborator-form row" v-show="!loading">
            <rdvmaf-input-text label="Nom" v-model="nom" class="col-lg-5 col-sm-12 col-xs-12" />
            <rdvmaf-input-text label="Prenom" v-model="prenom" class="col-lg-5 col-sm-12 col-xs-12" />
            <rdvmaf-input-text label="Adresse Mail" v-model="adresseMail" class="col-lg-5 col-sm-12 col-xs-12" :hasError="emailHasError"/>
            <p class="error-message">{{emailErrorMsg}}</p>
            <div class="form-bouttons">
                <rdvmaf-button class="boutton-valider" title="Valider" @click="valider" :disabled="!formIsValid" />
                <rdvmaf-button class="boutton-annuler" title="Annuler" @click="onClose" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import RdvmafPopin from '@/shared/rdvmaf-popin.vue';
import RdvmafInputText from '@/shared/rdvmaf-input-text.vue';
import RdvmafButton from '@/shared/rdvmaf-button.vue';
import compteService from '@/wapi/compte-service';
import { ICollaborator } from '@/models/user';
import RdvmafIcon from '@/shared/rdvmaf-icon.vue';
import { vxm } from '@/store';
import collaborateurService from '@/wapi/collaborateur-service';
import RdvmafSpinner from '@/shared/rdvmaf-spinner.vue';

@Component({
    components: {
        RdvmafPopin,
        RdvmafInputText,
        RdvmafButton,
        RdvmafIcon,
        RdvmafSpinner
    }
})
export default class CollaboratorForm extends Vue {
    @Prop({ required: true })
    private show!: boolean;

    private currentCollaborateur: ICollaborator | null = null;
    private loading: boolean = false;
    private emailHasError: boolean = false;
    private emailErrorMsg: string = "";

    private nom: string ="";
    private prenom: string ="";
    private adresseMail: string ="";

    public onClose() {
        this.$emit('on-close');
    }

    public async valider() {
        this.buildCollaborateur();
        if(this.currentCollaborateur){
            this.loading = true;
            this.emailHasError = false;
            this.emailErrorMsg = "";
            const result = await collaborateurService.addCollaborateur(this.currentCollaborateur);
            this.loading = false;
            if (result.isSucess) {
                this.$emit('add-collaborator-on-success');
            } else {
                this.emailHasError = true;
                this.emailErrorMsg = result.errors[0].message;
            }
        }
    }

    public buildCollaborateur() {
        this.currentCollaborateur = {
            identifier: '',
            displayName: this.nom + ' ' + this.prenom, 
            firstName: this.prenom,
            lastName: this.nom,
            referenceCompteId: vxm.app.currentUser?.compteId,
            referencePersonneId: vxm.app.currentUser?.personneId,
            roles: [],
            login: this.adresseMail.toLowerCase()
        } as ICollaborator;
    }

    @Watch('show')
    private showOnChange(newValue: boolean) {
        this.nom = '';
        this.prenom = '';
        this.adresseMail = '';
        this.emailErrorMsg = '';
        this.emailHasError = false;
    }

    get formIsValid(): boolean {
        return !!this.nom && !!this.prenom && !!this.adresseMail && !this.emailErrorMsg;
    }

    @Watch('adresseMail')
    private adresseMailOnChange(newValue: string){
        this.validEmail(newValue);
    }

    public validEmail(email: string) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email)) {
            this.emailErrorMsg = '';
            this.emailHasError = false;
        } else {
            this.emailErrorMsg = 'Adresse e-mail non valide!';
            this.emailHasError = true;
        }
    }
}
</script>

<style lang="scss">
@import '@/styles';

.collaborator-form {
    width: fit-content;
    padding: 15px;
    .rdvmaf-input-text {
        @include media-breakpoint-down(lg) {
            margin-left: 0px;
            margin-right: 0px;
        }
        @include media-breakpoint-up(lg) {
            margin-left: 30px;
            margin-right: 30px;
        }
    }
    .form-bouttons {
        display: flex;
        .rdvmaf-button {
            width: fit-content;
            margin-top: 10px;
            font-size: 14px;
            line-height: 10px;
            padding: 8px 10px;
            cursor: pointer;
        }
        .boutton-valider {
            background: black;
            margin-right: 20px;
            &:hover {
                background: $c-main !important;
            }
        }
        .boutton-annuler {
            background: transparent;
            color: black;
            &:hover {
                background: black !important;
                color: white !important;
            }
        }
    }
    .error-message {
        color: red;
        margin-left: 22px;
        font-size: 14px;
    }
}
</style>