<template>
    <div class="badge rounded-pill bg-white text-black">
        <i class="maficon-circle-check text-green-dark"></i>
        Inscrit
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import RdvmafIcon from '@/shared/rdvmaf-icon.vue';

@Component({
    components: {
        RdvmafIcon
    }
})
export default class RdvmafInscritTag extends Vue { }
</script>

<style lang="scss">
@import '@/styles';
</style>