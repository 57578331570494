import { ISubscription } from '@/models/subscription';
import evenementService from '@/wapi/evenement-service';
import { subscriptionService } from '@/wapi/subscription-service';
import { action, createModule, mutation } from 'vuex-class-component';

interface IAddSubscriptionParameters {
    evenementId: number; 
    subscriptions: ISubscription[];
};

const VuexModule = createModule({
    namespaced: 'inscription',
    strict: true
});

export class SubscriptionsStore extends VuexModule {
    private subscriptions: ISubscription[] = [];
    private loading: boolean = false;
    private isSubscriptionInitialized: boolean = false;

    public get getSubscriptions(): ISubscription[] {
        return this.subscriptions;
    }

    public get getIsSubscriptionInitialized(): boolean {
        return this.isSubscriptionInitialized;
    }

    public get getLoading(): boolean {
        return this.loading;
    }


    @mutation
    setIsSubscriptionInitialized(value: boolean): void {
        this.isSubscriptionInitialized = value;
    }

    @mutation
    private setSubscriptions(value: ISubscription[]): void {
        this.subscriptions = value;
    }

    @mutation
    setLoading(value: boolean): void {
        this.loading = value;
    }

    @action
    public async fetchUserSubscriptions(): Promise<void> {
        this.setLoading(true);
        this.setIsSubscriptionInitialized(false);
        const request = await subscriptionService.getMySubscriptions();
        this.setSubscriptions(request);
        this.setIsSubscriptionInitialized(true);
        this.setLoading(false);
    }

    @action
    public async addSubscriptions(payload: IAddSubscriptionParameters): Promise<void> {
        const copy = [...this.getSubscriptions];
        const subscriptionForEvenement = copy.filter(x => x.evenementId === payload.evenementId);
        const toAdd: ISubscription[] = [];
        const toRemove = subscriptionForEvenement.filter(x => !payload.subscriptions.some(y => y.inscritId === x.inscritId && y.createdById === x.createdById));
        payload.subscriptions.forEach(x => {
            const previousSubscriptionIndex = subscriptionForEvenement.findIndex(y => y.inscritId === x.inscritId && y.createdById === x.createdById);
            if (previousSubscriptionIndex > -1) {
                subscriptionForEvenement[previousSubscriptionIndex] = x;
            } else {
                toAdd.push(x);
            }
        });
        this.setSubscriptions(copy.filter(x => x.evenementId != payload.evenementId)
            .concat(subscriptionForEvenement.filter(x => !toRemove.some(y => y.createdById == x.createdById && y.inscritId == x.inscritId)))
            .concat(toAdd)
        );
        this.fetchUserSubscriptions();
    }

    @action async removeSubscriptions(evenementId: number): Promise<boolean> {
        const deleted = await evenementService.unsubscribe(evenementId);
        if (deleted) {
            this.setSubscriptions(this.getSubscriptions.filter(x => x.evenementId !== evenementId));
        }
        return deleted;
    }
}
