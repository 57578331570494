import INavigation from '@/models/navigation';
import { IUserProfile } from '@/models/user';
import axiosService, { isCallSucceeded } from '@/tools/axios-wrapper';
import { ICollaborator } from '../models/user';

class CompteService {
    private readonly url: string;
    constructor(url: string = 'compte') {
        this.url = url;
    }

    public async getUserProfile(): Promise<IUserProfile | null> {
        const apiResponse = await axiosService.get<IUserProfile>(this.url);
        if (apiResponse && isCallSucceeded(apiResponse.status)) {
            return apiResponse.data ?? null;
        }
        return null;
    }

    public async getNavigation(): Promise<INavigation | null> {
        const apiResponse = await axiosService.get<INavigation>(`${this.url}/navigation`);
        if (apiResponse && isCallSucceeded(apiResponse.status)) {
            return apiResponse.data ?? null;
        }
        return null;
    }
}

const compteService = new CompteService();
export default compteService;
