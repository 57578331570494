<template>
    <div class="programme-inscription" v-if="evenement">
        <div class="programme-title row" v-if="titlebar">
            <div class="item-title col-9">
                <p class="medium-text regular-text gray-text">{{ inscritName }}</p>
            </div>
            <div class="item-extender col-3" v-if="isCurrentUserSociete">
                <div class="clickable" @click="extended = !extended">
                    <p class="very-small-text regular-text">{{ extended ? 'Masquer le détail' : 'Voir le détail' }}</p>
                    <rdvmaf-icon :icon="icon" />
                </div>
            </div>
        </div>
        <div class="programme-body" v-if="extended">
            <div v-for="(key , index) in sortedKeys" :key="index">
                <p class="large-text regular-text" style="margin-top: 20px;margin-bottom: 10px;">{{getDateTitle(key)}}</p>
                <div class="element-programme row" v-for="element in getEtapesProgramme(key)" :key="element.key">
                    <p class="small-text regular-text primary-text heure-programme col-sm-1 col-3">{{ element.heure }}</p>
                    <p class="small-text regular-text sujet-programme col-sm-11 col-9 ">
                        {{ element.label }}
                        <rdvmaf-icon :icon="iconRepas" v-if="element.key == 'lunch' || element.key == 'breakfast'" />
                        <rdvmaf-icon :icon="iconRdvPerso" v-if="element.key == 'rdv-perso'" />
                    </p>
                </div>
            </div>
            <div class="programme-action row" v-if="actions">
                <div class="col-12 text-right">
                    <button @click="onUnsubscribe" class="desinscrire very-small-text bold-text">{{ desinscrireText }}</button>
                    <button @click="onEditSubscribtion" class="very-small-text bold-text"><rdvmaf-icon :icon="iconEdit" />{{ updateText }}</button>
                </div>
            </div>
            
        </div>
        <confirm-unsubscribe-popin
            v-model="showConfirmUnsubscribe"
            :inscritNom="inscritName"
            :evenementNom="evenementNom"
            :evenementDate="dateEvenement"
            @confirm="onConfirmUnsubscribe"
        />
    </div>
</template>

<script lang="ts">
import { IEvenement } from "@/models/evenement";
import { vxm } from "@/store";
import { Component, Prop, Vue } from "vue-property-decorator";
import RdvmafIcon, { IconSuffix } from '@/shared/rdvmaf-icon.vue';
import { convertTimeToString } from "@/tools/time-helper";
import { ICollaborator } from "@/models/user";
import { ISubscription } from "@/models/subscription";
import RdvmafButton from "@/shared/rdvmaf-button.vue";
import ConfirmUnsubscribePopin from "../inscription-presentiel/confirm-unsubscribe-popin.vue";
import { groupByKey } from "@/tools/array-helper";
import { addDays } from "@/tools/date-helper";

interface IEtapeProgramme {
    heure: string;
    label: string;
    key: string;
    time: Date;
}

@Component({
    components: {
        RdvmafIcon,
        RdvmafButton,
        ConfirmUnsubscribePopin
    }
})
export default class ProgrammeRdvPresentiel extends Vue {
    @Prop({ required: true })
    private inscription!: ISubscription;
    @Prop({ required: true })
    private actions!: boolean;
    @Prop({ required: true })
    private titlebar!: boolean;
    @Prop({ required: true })
    private index!: number;
    @Prop({ required: true })
    private evenement!: IEvenement;

    private extended: boolean = false;
    private showConfirmUnsubscribe: boolean = false;
    private evenementNom: string = "";
    public groupedProgrammes: IEtapeProgramme[][] = [];

    public get icon(): IconSuffix {
        if (this.extended) {
            return 'chevron-up';
        }
        return 'chevron-down';
    }

    public get iconRepas(): IconSuffix {
        return 'repas';
    }

    public get iconEdit(): IconSuffix {
        return 'edit-white';
    }

    public get isCurrentUserSociete(): boolean {
        return vxm.app.isCurrentUserSociete;
    }

    public get iconRdvPerso(): IconSuffix {
        return 'RDV-personnalise';
    }

    public get desinscrireText(): string {
        return this.isCurrentUserSociete ? `Désinscrire ${this.inscritName}` : `Annuler mon inscription`;
    }

    public get updateText(): string {
        return this.isCurrentUserSociete ? `Modifier le programme` : `Modifier mon programme`;
    }

    get dateEvenement(): string {
        if (this.evenement?.dateEvenement) {
            const result = new Date(this.evenement.dateEvenement).toLocaleDateString('fr-FR', {
                day: 'numeric',
                weekday: 'long',
                month: 'long',
                year: 'numeric'
            });
            return `${result[0].toUpperCase()}${result.slice(1)}`;
        }
        return '';
    }

    get inscritName(): string | null {
        return this.inscription.nom;
    }

    created() {
        this.extended = !this.isCurrentUserSociete || !this.titlebar || this.index === 0;
        if (this.evenement) {
            this.evenementNom = this.evenement?.sujet;
        }
    }

    public duration(duree: number): string {
        const date = new Date(0, 0);
        date.setMinutes(duree);
        return convertTimeToString(date);
    }

    public getEtapesProgramme(dateJour: number): IEtapeProgramme[] {
        if (this.inscription) {
            const sessionAtelierIds = this.inscription.inscriptionsSessionAtelier?.filter(x => x.sessionAtelierId !== null).map(x => x.sessionAtelierId!) ?? [];

            let result = this.evenement?.sessions.filter(s => s.jourIndex === dateJour).flatMap(session => {
                return session.sessionAteliers.filter(x => sessionAtelierIds.includes(x.sessionAtelierId)).map(sessionAtelier => {
                    return {
                        heure: convertTimeToString(new Date(session.debut)),
                        label: `${sessionAtelier.atelier.sujet} (${this.duration(sessionAtelier.atelier.duree)})`,
                        key: `recap-rdv-${sessionAtelier.sessionAtelierId}`,
                        time: new Date(session.debut)
                    };
                });
            }) ?? [];

            const creneau = this.evenement?.creneauxRendezVous.find(x => x.creneauRendezVousId === this.inscription.creneauRendezVousId
             && x.jourIndex === dateJour);
            if (creneau) {
                result.push({
                    heure: convertTimeToString(new Date(creneau.debut)),
                    label: 'RDV personnalisé',
                    key: `rdv-perso`,
                    time: new Date(creneau.debut)
                });
            }

            const date = new Date(this.evenement!.dateEvenement!);
            if (this.inscription.dejeuner && this.evenement?.debutDejeuner && this.evenement?.hasDejeuner) {
                const lunchTime = new Date(date);
                lunchTime.setHours(new Date(this.evenement?.debutDejeuner).getHours());
                lunchTime.setMinutes(new Date(this.evenement?.debutDejeuner).getMinutes());
                result.push({
                    heure: convertTimeToString(new Date(this.evenement?.debutDejeuner)),
                    label: 'Déjeuner',
                    key: 'lunch',
                    time: lunchTime
                });
            }
            if (this.inscription.petitDejeuner && this.evenement?.debutPetitDejeuner && this.evenement?.hasPetitDejeuner) {
                const breakfastTime = new Date(date);
                breakfastTime.setTime(new Date(this.evenement?.debutPetitDejeuner).getHours());
                breakfastTime.setMinutes(new Date(this.evenement?.debutPetitDejeuner).getMinutes());
                result.push({
                    heure: convertTimeToString(new Date(this.evenement?.debutPetitDejeuner)),
                    label: 'Petit déjeuner',
                    key: 'breakfast',
                    time: breakfastTime
                });
            }
            if (this.inscription.diner && this.evenement?.debutDiner && this.evenement?.hasDiner) {
                const dinner = new Date(date);
                dinner.setHours(new Date(this.evenement?.debutDiner).getHours());
                dinner.setMinutes(new Date(this.evenement?.debutDiner).getMinutes());
                result.push({
                    heure: convertTimeToString(new Date(this.evenement?.debutDiner)),
                    label: 'Dîner',
                    key: 'diner',
                    time: dinner
                });
            }

            result.sort((a, b) => a.time.getTime() - b.time.getTime());
            return result;
        }
        return [];
    }

    get sortedKeys(): number[]{
        let jourIndexes : number[] = [];
        const sessionAtelierIds = this.inscription.inscriptionsSessionAtelier?.filter(x => x.sessionAtelierId !== null).map(x => x.sessionAtelierId!) ?? [];
        let sessionAteliersJourIndexes = this.evenement?.sessions.flatMap(session => {
                return session.sessionAteliers.filter(x => sessionAtelierIds.includes(x.sessionAtelierId)).map(sessionAtelier => {
                    return session.jourIndex;
                });
            }) ?? [];
        jourIndexes = sessionAteliersJourIndexes.filter((n, i) => sessionAteliersJourIndexes.indexOf(n) === i)
        const indexCrenaux = this.evenement?.creneauxRendezVous.find(x => x.creneauRendezVousId === this.inscription.creneauRendezVousId)?.jourIndex ?? null;
        if(indexCrenaux && jourIndexes.indexOf(indexCrenaux) === -1){
            jourIndexes.push(indexCrenaux);
        }
        return jourIndexes;
    }

    onEditSubscribtion() {
        this.$emit('edit-subscription', this.inscription.inscriptionId);
    }

    onEditUpSubscribtion() {
        this.$emit('edit-up-subscription');
    }

    onUnsubscribe() {
        this.showConfirmUnsubscribe = true;
    }

    onConfirmUnsubscribe() {
        this.$emit('delete-subscription', this.inscription.inscriptionId, this.inscription.inscritId, this.inscription.nom);
    }
    public getDateTitle(dateIncriment: number): string {
        const evenement = vxm.evenements.getCurrentEvenement;
        if(evenement){
            const currentDateEvenement = addDays(dateIncriment, new Date(evenement.dateEvenement));
            const local ='fr-FR';
            return currentDateEvenement.toLocaleDateString(local, { day: 'numeric', weekday: 'long', month: 'long'});
        }
        return '';        
    }
}
</script>

<style lang="scss">
@import '@/styles';
.programme-inscription {
    margin-bottom: 20px;
    .programme-title {
        margin-bottom: 16px;
        .item-title {
            padding-left: 0px;
        }
        .item-extender {
            padding-right: 0px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            .clickable {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                p {
                    margin-bottom: 0px;
                }
                &:hover {
                    p {
                        color: $c-main;
                    }
                }
            }
        }
    }
    .programme-body {
        .element-programme {
            padding: 16px 24px;
            border: 0.5px solid #E0E0E0;
            .heure-programme {

            }
            .sujet-programme {

            }
        }
        .programme-action {
            > div {
                padding: 16px 0;
                justify-content: end;
                button {
                    width: auto;
                    height: 32px;
                    background: #000000;
                    border: 1px solid #000000;
                    color: #ffffff;
                    padding: 8px 16px;
                    &.desinscrire {
                        background: transparent;
                        color: #000000;
                        border: none;
                        &:hover {
                            color: $c-primary;
                            background: transparent;
                            border: none;
                        }
                    }
                    &:hover {
                        background: $c-primary;
                        border: 1px solid $c-primary;
                    }
                    .rdvmaf-icon {
                        height: 16px;
                        width: 16px;
                    }
                }
            }
        }
    }
}
</style>