import { IEvenement } from '@/models/evenement';
import evenementService from '@/wapi/evenement-service';
import { action, createModule, mutation } from 'vuex-class-component';

const VuexModule = createModule({
    namespaced: 'evenements',
    strict: true
});

export class EvenementStore extends VuexModule {
    private currentEvenement: IEvenement | null = null;
    private loading: boolean = false;

    get getCurrentEvenement() {
        return this.currentEvenement;
    }

    get getLoading() {
        return this.loading;
    }

    @mutation
    private setCurrentEvenement(evenement: IEvenement | null) {
        this.currentEvenement = evenement;
    }

    @mutation
    public setLoading(loading: boolean) {
        this.loading = loading;
    }

    @action
    public async fetchEvenementById(id: number) {
        const evenement = await evenementService.getById(id);
        this.setCurrentEvenement(evenement);
    }
}
