<template>
    <div class="rdvmaf-intervenant-item col-12">
        <intervenant-badge class="rdvmaf-intervenant-image" :intervenant="getIntervenant" />
        <div>
            <div class="rdvmaf-intervenant-name">
                <span v-if="getIntervenant.titre">{{ getIntervenant.titre }} </span>{{ getIntervenant.prenom }} {{ getIntervenant.nom }}
            </div>
            <div class="rdvmaf-intervenant-information">
                <span class="rdvmaf-intervenant-societe" v-if="getIntervenant.societe">{{ getIntervenant.societe }}</span>
                <span>{{ intervenantFunction }}</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { IEvenementIntervenant } from '@/models/evenement';
import { IIntervenant } from '@/models/intervenant';
import { Component, Vue, Prop } from 'vue-property-decorator';
import IntervenantBadge from './intervenant-badge.vue'
import { capitalizeOnlyFirstLetter } from "@/tools/string-helper";

@Component({
    components: {
        IntervenantBadge
    }
})
export default class IntervenantItem extends Vue {
    @Prop({ required: true })
    private intervenant!: IEvenementIntervenant | IIntervenant;

    get getIntervenant(): IIntervenant {
        return Object.keys(this.intervenant).some(x => x === 'intervenant') ? (this.intervenant as IEvenementIntervenant).intervenant : this.intervenant as IIntervenant;
    }

    get intervenantFunction(): string {
        return capitalizeOnlyFirstLetter(this.getIntervenant.fonction ? this.getIntervenant.fonction : '');
    }
}
</script>

<style lang="scss">
@import "@/styles";
.rdvmaf-intervenant-item {
    @include media-breakpoint-down(lg) {
        margin: 0px;
    }
    @include media-breakpoint-up(lg) {
        margin: 16px;
    }
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    width: 100%;

    .rdvmaf-intervenant-image {
        height: 74px;
        object-fit: cover;
        border-radius: 50%;
    }

    > div {
        margin-top: 10px;
        line-break: strict;
        font-size: 14px;
        height: 50%;
        width: 70%;
        .rdvmaf-intervenant-name {
            font-family: $museo-sans-600-normal;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
        }
        .rdvmaf-intervenant-information {
            font-family: $museo-sans-300-normal;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
        }
        .rdvmaf-intervenant-societe {
            font-family: $museo-sans-400-normal;
            font-weight: 400;
            color: $c-button-disabled;
            border-right: 1.5px solid #E0E0E0;
            padding-right: 10px;
            margin-right: 10px;
        }
    }
}
</style>