import { DesinscriptionRdvMafDto } from "@/data-contracts/desinscription-rdv-maf-dto";
import { InscriptionDto } from "@/data-contracts/inscription-dto";
import { InscrireAdherentLivestormDto } from "@/data-contracts/inscrire-adherent-livestorm-dto";
import { ISubscription } from "@/models/subscription";
import axiosService, { isCallSucceeded } from "@/tools/axios-wrapper";

class SubscriptionService {
    private readonly url: string;
    constructor(url = 'api/inscription') {
        this.url = url;
    }

    public async getMySubscriptions(): Promise<ISubscription[]> {
        const apiResponse = await axiosService.get<ISubscription[]>(`${this.url}/me`);
        if (apiResponse && isCallSucceeded(apiResponse.status)) {
            return apiResponse.data ?? [];
        }
        return [];
    }

    public async getMySubscriptionsByEvenementId(evenementId: number): Promise<ISubscription[]> {
        const apiResponse = await axiosService.get<ISubscription[]>(`${this.url}/me/${evenementId}`);
        if (apiResponse && isCallSucceeded(apiResponse.status)) {
            return apiResponse.data ?? [];
        }
        return [];
    }

    public async unsubscribePresentiel(inscriptionId: number, subscriptionDto: DesinscriptionRdvMafDto): Promise<any> {
        const apiResponse = await axiosService.post<boolean, DesinscriptionRdvMafDto>(
            `${this.url}/{id}/desinscrire`,
            subscriptionDto,
            null,
            { id: `${inscriptionId}` });
        return apiResponse && isCallSucceeded(apiResponse.status) ? apiResponse.data ?? null : null;
    }

    public async getAllSubscriptionsByEvenementId(evenementId: number): Promise<InscriptionDto[]> {
        const apiResponse = await axiosService.get<InscriptionDto[]>(`${this.url}/all/${evenementId}`);
        if (apiResponse && isCallSucceeded(apiResponse.status)) {
            return apiResponse.data ?? [];
        }
        return [];
    }

    public async scanPresenceInscription(inscriptionId: number): Promise<Date | null> {
        const apiResponse = await axiosService.post<Date | null>(
            `${this.url}/{id}/scan-presence`,
            null,
            null,
            { id: `${inscriptionId}` });
        if (apiResponse && isCallSucceeded(apiResponse.status)) {
            return apiResponse.data ?? null;
        }
        return null;
    }


    async inscrireLiveStorm(evenementId: number): Promise<InscrireAdherentLivestormDto | null> {
        const apiResponse = await axiosService.get<InscrireAdherentLivestormDto| null>(
            `${this.url}/${evenementId}/inscription-livestorm`,
            null,
            null,
            null);
            if (apiResponse && isCallSucceeded(apiResponse.status)) {
                return apiResponse.data ?? null;
            }
            return null;
    }    
}

export const subscriptionService = new SubscriptionService();