import axios, { AxiosRequestConfig, ResponseType as AxiosResponseType, AxiosResponse } from 'axios';

interface IParameteredRequestType {
    post: string;
    put: string;
    patch: string;
}

interface IParameterlessRequestType {
    get: string;
    delete: string;
}

interface ICallType extends IParameteredRequestType, IParameterlessRequestType {}

export function isCallSucceeded(code: number): boolean {
    return code === 200 || code === 201 || code === 203 || code === 204;
}

export interface IBaseCallParam<P> {
    payload?: P | null;
    headers?: Record<string, any> | null;
    responseType?: AxiosResponseType;
    queryParameters?: Record<string, string | number | boolean | null> | null;
    urlParameters?: Record<string, string> | null;
}

export interface IApiError {
    message?: string;
    statusCode?: number;
}

export interface ApiResponse<T = null> {
    data: T;
}

class AxiosServiceNew {
    constructor() {
        this.initWeb();
    }

    private initWeb() {
        axios.interceptors.request.use(this.requestInterceptor);
    }

    private async requestInterceptor(configs: AxiosRequestConfig): Promise<AxiosRequestConfig> {
        configs.headers = {
            'Content-Type': 'application/json'
        };
        return configs;
    }

    private createConfigs(headers?: Record<string, any> | null, responseType?: AxiosResponseType): AxiosRequestConfig {
        const config: AxiosRequestConfig = {
            headers,
            responseType
        };
        return config;
    }

    private async callWithoutParams<R>(
        url: string,
        type: keyof IParameterlessRequestType,
        config: AxiosRequestConfig
    ): Promise<AxiosResponse<R>> {
        const response = await axios[type](url, config);
        return response;
    }

    private async callWithParams<R, P = null>(
        url: string,
        type: keyof IParameteredRequestType,
        config: AxiosRequestConfig,
        payload?: P | null
    ): Promise<AxiosResponse<R>> {
        const response = await axios[type](url, payload ?? undefined, config);
        return response;
    }

    private async baseCall<R, P = null>(
        url: string,
        type: keyof ICallType,
        params: IBaseCallParam<P> = {
            headers: null,
            payload: null
        }
    ): Promise<ApiResponse<R>> {
        let result: ApiResponse<R> | null = null;
        const config = this.createConfigs();
        let request: Promise<AxiosResponse<R>> | null = null;
        const requestUrl = this.prepareUrl(url, params.queryParameters, params.urlParameters);
        switch (type) {
            case 'get':
            case 'delete':
                request = this.callWithoutParams<R>(requestUrl, type, config);
                break;
            case 'put':
            case 'patch':
            case 'post':
                request = this.callWithParams<R, P>(requestUrl, type, config, params.payload);
                break;
        }
        try {
            if (request) {
                const response: AxiosResponse<R> = await request;
                if (response && isCallSucceeded(response.status)) {
                    result = { data: response.data };
                } else {
                    const error = response.data as IApiError;
                    result = { data: response.data };
                }
            }
        } catch (err: { response: AxiosResponse<IApiError> } | any) {
            if (err.response && err.response.data) {
                result = {
                    data: err.response.data
                };
            } else if (err.response) {
                // Endpoint Not found or all other error that not returned by API
                result = {
                    data: err.response.data
                };
                if (window.location.href.indexOf("recapitulatif") > -1) {
                    throw result.data;
                }
            }
        }
        return result!;
    }

    private prepareUrl(
        url: string,
        queryParameters: Record<string, string | number | boolean | null> | null = null,
        urlParameters: Record<string, string> | null = null
    ) {
        let result = `${document.location.origin}/${url}`;
        if (queryParameters) {
            result += '?';
            result = result.concat(
                Object.entries(queryParameters)
                    .filter((tuple) => tuple[1] !== undefined && tuple[1] !== null)
                    .map((tuple) => `${tuple[0]}=${encodeURIComponent(tuple[1]!)}`)
                    .reduce((a, b) => `${a}&${b}`)
            );
        }
        if (urlParameters) {
            Object.entries(urlParameters).forEach((tuple) => {
                result = result.replaceAll(`{${tuple[0]}}`, encodeURIComponent(tuple[1]!));
            });
        }
        return result;
    }

    public async get<R>(
        url: string,
        queryParams: Record<string, string | number | boolean | null> | null = null,
        urlParams: Record<string, string> | null = null,
        headers: Record<string, string> | null = null
    ): Promise<ApiResponse<R>> {
        return await this.baseCall<R>(url, 'get', {
            queryParameters: queryParams,
            urlParameters: urlParams,
            headers: headers,
            payload: null
        });
    }

    public async delete<R>(
        url: string,
        queryParams: Record<string, string | number | boolean | null> | null = null,
        urlParams: Record<string, string> | null = null,
        headers: Record<string, string> | null = null
    ): Promise<ApiResponse<R>> {
        return await this.baseCall<R>(url, 'delete', {
            queryParameters: queryParams,
            urlParameters: urlParams,
            headers: headers,
            payload: null
        });
    }

    public async post<R, P = null>(
        url: string,
        payload: P,
        queryParams: Record<string, string | number | boolean | null> | null = null,
        urlParams: Record<string, string> | null = null,
        headers: Record<string, string> | null = null
    ): Promise<ApiResponse<R>> {
        return await this.baseCall<R, P>(url, 'post', {
            queryParameters: queryParams,
            urlParameters: urlParams,
            headers: headers,
            payload: payload
        });
    }

    public async put<R, P = null>(
        url: string,
        payload: P,
        queryParams: Record<string, string | number | boolean | null> | null = null,
        urlParams: Record<string, string> | null = null,
        headers: Record<string, string> | null = null
    ): Promise<ApiResponse<R>> {
        return await this.baseCall<R, P>(url, 'put', {
            queryParameters: queryParams,
            urlParameters: urlParams,
            headers: headers,
            payload: payload
        });
    }

    public async patch<R, P = null>(
        url: string,
        payload: P,
        queryParams: Record<string, string | number | boolean | null> | null = null,
        urlParams: Record<string, string> | null = null,
        headers: Record<string, string> | null = null
    ): Promise<ApiResponse<R>> {
        return await this.baseCall<R, P>(url, 'patch', {
            queryParameters: queryParams,
            urlParameters: urlParams,
            headers: headers,
            payload: payload
        });
    }
}

const axiosService = new AxiosServiceNew();

export default axiosService;
