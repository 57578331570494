import INavigation, { IAcces } from '@/models/navigation';
import { IUserProfile, TypePersonne, getTypePersonne, TypePersonneLabel, ICollaborator, IUser } from '@/models/user';
import compteService from '@/wapi/compte-service';
import collaborateurService from '@/wapi/collaborateur-service';
import { action, createModule, mutation } from 'vuex-class-component';

const VuexModule = createModule({
    namespaced: 'app',
    strict: true
});

export class AppStore extends VuexModule {
    private userProfile: IUserProfile | null = null;
    private navigation: INavigation | null = null;
    private loading: boolean = false;
    private collaborateurList: ICollaborator[] | null = null;

    public get profile(): IUserProfile | null {
        return this.userProfile;
    }

    public get currentUser(): IUser | null {
        return this.userProfile?.user ?? null;
    }

    public get typePersonne(): TypePersonneLabel {
        return getTypePersonne(this.userProfile);
    }

    public get isCurrentUserSociete(): boolean {
        return ['Société'].includes(this.typePersonne);
    }

    public get isCurrentUserCollaborateur(): boolean {
        return ['Collaborateur'].includes(this.typePersonne);
    }

    public get isCurrentUserLiberal(): boolean {
        return ['Libéral'].includes(this.typePersonne);
    }
    
    public get getNavigation(): INavigation | null {
        return this.navigation;
    }

    public get getLoading(): boolean {
        return this.loading;
    }

    public get mesCoordonnees(): IAcces | null {
        if (this.navigation) {
            return this.navigation.profils.find(x => x.libelle.includes('coord')) ?? null;
        }
        return null;
    }

    public get collaborateursLink(): IAcces | null {
        if (this.navigation) {
            return this.navigation.profils.find(x => x.libelle.includes('collaborateurs')) ?? null;
        }
        return null;
    }

    public get collaborateursList(): ICollaborator[] | null {
        if (this.loading || this.typePersonne === 'Anonymous' || !this.userProfile) {
            return null;
        } else if (this.typePersonne === 'Société') {
            return this.collaborateurList;
        } else {
            const userAsCollab: ICollaborator = {
                identifier:  this.userProfile.user.identifier,
                displayName: this.userProfile.user.name,
                firstName: '',
                lastName: '',
                login: this.userProfile.user.emailAdherent,
                referenceCompteId: this.userProfile.user.compteId,
                referencePersonneId: this.userProfile.user.personneId,
                roles: this.userProfile.user.roles
            };
            if(this.typePersonne == 'Libéral'){
                return [userAsCollab].concat(this.collaborateurList ?? []);
            }
            return [userAsCollab];
        }
    }

    public get currentUserName(): string | null {
        if(this.userProfile?.user.isCollaborateur){
            return this.userProfile?.user.prenom + " " + this.userProfile?.user.nom ?? null;
        }
        return this.userProfile?.user.name ?? null;
    }

    public get currentUserEmail(): string | null {
        if(this.userProfile?.user.isCollaborateur){
            return this.userProfile?.user.loginSecondaire ?? null;
        }
        return this.userProfile?.user.emailAdherent ?? null;
    }

    @mutation
    private setUserProfile(value: IUserProfile | null): void {
        this.userProfile = value;
    }

    @mutation setNavigation(value: INavigation | null) {
        this.navigation = value;
    }

    @mutation
    setLoading(value: boolean): void {
        this.loading = value;
    }

    @mutation
    private setCollaborateurList(value: ICollaborator[] | null) {
        if(value){
            value.forEach(element => {
                if(!element.displayName){
                    element.displayName = element.lastName + ' ' + element.firstName
                }
            });
        }        
        this.collaborateurList = value;
    }

    @action
    public async fetchUserProfile(): Promise<void> {
        this.setLoading(true);
        let profile = await compteService.getUserProfile();
        if (profile?.user.compteId) {
            const list = await collaborateurService.getCollaborateurList(profile.user.compteId);
            if (this.profile?.user.isCollaborateur && list?.value != null) {
                const compteColaborateur: ICollaborator = list?.value?.filter(x => x.login === profile?.user.loginSecondaire)[0];
                if (compteColaborateur) {
                    profile.user.name = compteColaborateur.displayName;
                    profile.user.emailAdherent = compteColaborateur.login;
                }
            }
            this.setCollaborateurList(list?.value);
            this.setUserProfile(profile);
            this.setLoading(false);
        }
    }

    @action async fetchNavigation(): Promise<void> {
        this.setLoading(true);
        const navigation = await compteService.getNavigation();
        this.setNavigation(navigation);
        this.setLoading(false);
    }
}
