export function redirectToRoute(route: string) {
    const currentPathName = window.location.pathname;
    let finalRoute = route.startsWith('/') ? route : `/${route}`;
    if ((currentPathName.startsWith('/c') && currentPathName.length == 2) || currentPathName.startsWith('/c/')) {
        finalRoute = '/c' + finalRoute;
    }
    window.location.href = `${window.location.origin}${finalRoute}`;
}

export function getFullRoute(route: string) {
    const currentPathName = window.location.pathname;
    let finalRoute = route.startsWith('/') ? route : `/${route}`;
    if ((currentPathName.startsWith('/c') && currentPathName.length == 2) || currentPathName.startsWith('/c/')) {
        finalRoute = '/c' + finalRoute;
    }
    return `${window.location.origin}${finalRoute}`;
}