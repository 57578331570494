export function addDays(numOfDays: number, date = new Date()) {
    date.setDate(date.getDate() + numOfDays);
  
    return date;
  }

  export function getLongDateString(endDateEvenement: Date|null) {
    return endDateEvenement? endDateEvenement.toLocaleDateString('fr-FR', {
      day: 'numeric',
      weekday: 'long',
      month: 'long'
    }):'';
  }