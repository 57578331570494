<template>
    <button class="rdvmaf-button" :disabled="disabled" @click="onClick">
        <slot name="content">{{ title }}</slot>
    </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RdvmafButton extends Vue {
    @Prop({ required: false, default: () => '' })
    private title!: string;
    @Prop({ required: false, default: () => false })
    private disabled!: boolean;

    public onClick() {
        this.$emit('click');
    }
}
</script>

<style lang="scss">
@import '@/styles';
.rdvmaf-button {
    width: -webkit-fill-available;
    background: $c-primary;
    font-family: $ff-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 21px;
    color: white;
    padding: 16px 20px;
    margin: 40px 0 0 0;
    border: 0px;
    cursor: pointer;
    &:disabled {
        cursor: default;
        background: $c-button-disabled;
    }
    &:hover {
        background: $c-button-hover !important;
    }
}
</style>
