<template>
    <div class="rdvmaf-textarea" :style="`${width ? 'width:' + width + ';' : '' } ${height ? 'height:' + height + ';' : '' }`" >
        <textarea :value="value" @input="updateValue($event.target.value)" :disabled="disabled" :placeholder="label" />
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from 'vue-property-decorator';

@Component
export default class RdvmafTextarea extends Vue {
    @Prop( { required: false, default: () => null })
    private label!: string | null;

    @Prop( { required: true })
    private value!: string;

    @Prop({ required: false, default: () => false })
    private disabled!: boolean;

    @Prop({ required: false, default: () => null })
    private width!: string | null;

    @Prop({ required: false, default: () => "122px" })
    private height!: string;

    public updateValue(value: string) {
        this.$emit('input', value)
    }

}
</script>

<style lang="scss">
@import '@/styles';
.rdvmaf-textarea {
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-width: 1px 1px 2px 1px;
    border-color: #E0E0E0 #E0E0E0 black #E0E0E0;
    border-radius: 3px;
    padding-left: 8px;
    margin: 10px;
    textarea {
        border-width: 0px;
        font-family: $museo-sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: rgba(30, 30, 30, 1);
        background: none;
        resize: none;
        width: 100%;
        height: 100%; 
        &:focus-visible {
            outline: none;
        }
        &:disabled {
            color: rgba(30, 30, 30, 0.7);
        }
        ::placeholder {
            color: #828282;
        }
    }
}
</style>