export enum TypePersonne {
    Physique = 1,
    Morale = 2,
    Anonymous = 3
}

export type TypePersonneLabel = 'Collaborateur' | 'Société' | 'Libéral' | 'Anonymous';

export interface IUser {
    identifier: string;
    emailAdherent: string;
    raisonSociale: string;
    isIngenieur: boolean;
    isArchitecteInterieur: boolean;
    isAutreConcepteur: boolean;
    hasCourtier: boolean;
    hasCourtierInternational: boolean;
    hasCourtierFrance: boolean;
    isArchitecte: boolean;
    isAdherentArchitecteEuropeen: boolean;
    comptes: number[];
    roles: string[];
    nomAssureur: string;
    name: string;
    nom: string;
    prenom: string;
    loginSecondaire: string;
    courtierId: number;
    societeId: number;
    compteInternational: number | null;
    personneId: number;
    compteId: number;
    matriculeNational: string;
    isEuroMAF: boolean;
    isCollaborateur: boolean;
    typePersonneId: number;
}

export interface ICollaborator {
    identifier: string;
    displayName: string;
    firstName: string;
    lastName: string;
    referenceCompteId: number | null;
    referencePersonneId: number | null;
    roles: number[] | string[];
    login: string;
}

export interface IUserProfile {
    user: IUser;
    typePersonneId: number;
}

export function getTypePersonne(user: IUserProfile | null): TypePersonneLabel {
    if (user?.user?.identifier.toString() ?? "0" !== "0") {
        if (user!.user!.isCollaborateur) {
            return "Collaborateur";
        }
        if (user!.typePersonneId === TypePersonne.Physique) {
            return "Libéral";
        }
        return "Société";
    }
    return "Anonymous";
}