import { SessionAtelierDto } from '@/data-contracts/session-atelier-dto';
import { InscriptionRdvMafDto } from '@/data-contracts/inscription-rdv-maf-dto';
import { IEvenement } from '@/models/evenement';
import { IInscriptionSessionAtelier, ISubscription } from '@/models/subscription';
import { getTypePersonne, ICollaborator } from '@/models/user';
import compteService from '@/wapi/compte-service';
import evenementService from '@/wapi/evenement-service';
import { subscriptionService } from '@/wapi/subscription-service';
import { action, createModule, mutation } from 'vuex-class-component';
import { DesinscriptionRdvMafDto } from '@/data-contracts/desinscription-rdv-maf-dto';
import collaborateurService from '../wapi/collaborateur-service';

const VuexModule = createModule({
    namespaced: 'inscriptionRdv',
    strict: true
});

export class InscriptionRdvStore extends VuexModule {
    private subscription: ISubscription | null = null;
    private currentEvenement: IEvenement | null = null;
    private loading: boolean = false;
    private showAtelierOrRendevousRequiredAlert: boolean = false;
    private showRendevousRequiredAlert: boolean = false;
    private showCommentaireRequiredAlert: boolean = false;
    private alreadySubscribedPersons: string[] = [];
    private rdvChecked: boolean = false;
    private showInvitationPopin: boolean = false;
    private showInvitationSuccessAlert: boolean = false;

    private inscritName: string | null = null;


    public get getParticipantId(): string | null {
        return this.subscription?.inscritId ?? null;
    }
    public get getSubscriptionsAtelierAndRendezVous(): IInscriptionSessionAtelier[] {
        return this.subscription?.inscriptionsSessionAtelier ?? [];
    }
    public get getSessionAtelierIds(): number[] {
        return this.subscription?.inscriptionsSessionAtelier?.filter(x => x.sessionAtelierId !== null).map(x => x.sessionAtelierId!) ?? [];
    }
    public get getBreakfast(): boolean {
        return this.subscription?.petitDejeuner ?? true;
    }
    public get getLunch(): boolean {
        return this.subscription?.dejeuner ?? true;
    }
    public get getDiner(): boolean {
        return this.subscription?.diner ?? true;
    }

    public get getShowAtelierOrRendevousRequiredAlert(): boolean {
        return this.showAtelierOrRendevousRequiredAlert;
    }

    public get getShowRendevousRequiredAlert(): boolean {
        return this.showRendevousRequiredAlert;
    }

    public get getShowCommentaireRequiredAlert(): boolean {
        return this.showCommentaireRequiredAlert;
    }

    public get getShowInvitationPopin(): boolean {
        return this.showInvitationPopin;
    }

    public get getShowInvitationSuccessAlert(): boolean {
        return this.showInvitationSuccessAlert;
    }

    public get getLoading(): boolean {
        return this.loading;
    }

    public get getEvenement(): IEvenement | null {
        return this.currentEvenement;
    }
    public get getRdvChecked(): boolean {
        return this.rdvChecked;
    }

    public get getInscritName(): string | null {
        return this.inscritName;
    }

    public get getInscriptionRdvMafForSaving(): InscriptionRdvMafDto | null {
        if (this.subscription) {
            const inscription: ISubscription = JSON.parse(JSON.stringify(this.subscription)) as ISubscription;
            const inscrriptionRdv: InscriptionRdvMafDto = {
                inscriptionId: inscription.inscriptionId,
                inscritId: inscription.inscritId,
                inscritNom: inscription.nom,
                hasDejeuner: inscription.dejeuner ?? false,
                hasPetitDejeuner: inscription.petitDejeuner ?? false,
                hasDiner: inscription.diner ?? false,
                creneauRendezVousId: inscription.creneauRendezVousId,
                sujetRendezVousId: inscription.sujetRendezVousId,
                commentaires: inscription.commentaires
            } as InscriptionRdvMafDto;
            if (inscription.inscriptionsSessionAtelier) {
                inscrriptionRdv.sessionAteliers = [];
                inscription.inscriptionsSessionAtelier.forEach(inscriptionSessionAtelier => {
                    inscrriptionRdv.sessionAteliers.push({
                        sessionAtelierId: inscriptionSessionAtelier.sessionAtelierId
                    } as SessionAtelierDto);
                });
            }
            return inscrriptionRdv;
        }
        return null;
    }

    public get getAlreadySubscribedPersons(): string[] {
        return this.alreadySubscribedPersons;
    }

    public get getInscription(): ISubscription | null {
        return this.subscription;
    }

    @mutation
    private setParticipant(newValue: ICollaborator | null) {
        if (this.subscription && newValue) {
            this.subscription.inscritId = newValue.identifier;
        }
    }

    @mutation
    private setBreakfast(newValue: boolean) {
        if (this.subscription) {
            this.subscription.petitDejeuner = newValue;
        }
    }

    @mutation
    private setLunch(newValue: boolean) {
        if (this.subscription) {
            this.subscription.dejeuner = newValue;
        }
    }

    @mutation
    private setDinner(newValue: boolean) {
        if (this.subscription) {
            this.subscription.diner = newValue;
        }
    }

    @mutation
    public initRendezVousPerso() {
        if (this.subscription) {
            this.subscription.commentaires = "";
            this.subscription.sujetRendezVousId = null;
            this.subscription.creneauRendezVousId = null;
        }
    }

    @mutation
    private setSubscription(subscription: ISubscription) {
        this.subscription = subscription;
    }

    @mutation
    private setEvenement(evenement: IEvenement) {
        this.currentEvenement = evenement;
    }

    @mutation
    public setLoading(value: boolean) {
        this.loading = value;
    }

    @mutation
    public setShowAtelierOrRendevousRequiredAlert(value: boolean) {
        this.showAtelierOrRendevousRequiredAlert = value;
    }

    @mutation
    public setShowRendevousRequiredAlert(value: boolean) {
        this.showRendevousRequiredAlert = value;
    }

    @mutation
    public setShowCommentaireRequiredAlert(value: boolean) {
        this.showCommentaireRequiredAlert = value;
    }

    @mutation
    public setShowInvitationPopin(value: boolean) {
        this.showInvitationPopin = value;
    }

    @mutation
    public setShowInvitationSuccessAlert(value: boolean) {
        this.showInvitationSuccessAlert = value;
    }

    @mutation
    private setAtelierCreneauSubscriptionList(newList: IInscriptionSessionAtelier[]) {
        if (this.subscription) {
            this.subscription.inscriptionsSessionAtelier = newList;
        }
    }

    @mutation
    public setInscritName(value: string) {
        this.inscritName = value;
    }

    @mutation
    public setAlreadySubscribedPersons(value: string[]) {
        this.alreadySubscribedPersons = value;
    }

    @mutation
    public setSubscriptionRendezVouRdvChecked(value: boolean) {
        this.rdvChecked = value;
    }

    @action async fetchSubscriptionAndEvenement(payload: { evenementId: number, inscriptionId: number}): Promise<void> {
        this.setLoading(true);
        const evenement: IEvenement | null = await evenementService.getById(payload.evenementId);
        if (evenement) {
            this.setEvenement(evenement);
            const inscriptions = await subscriptionService.getMySubscriptionsByEvenementId(payload.evenementId);
            if (inscriptions) {
                this.setAlreadySubscribedPersons(inscriptions.map((x) => x.inscritId));
                const inscription = inscriptions.find((x) => x.inscriptionId === payload.inscriptionId);
                if (inscription) {
                    if (inscription.inscriptionsSessionAtelier) {
                        this.setAtelierCreneauSubscriptionList(inscription.inscriptionsSessionAtelier);
                    }

                    this.setSubscription(inscription);
                    this.setInscritName(inscription.nom);
                }
            }
        }
        this.setLoading(false);
    }

    @action async initEvenementAndSubscription(evenementId: number): Promise<void> {
        this.setLoading(true);
        const evenement: IEvenement | null = await evenementService.getById(evenementId);
        if (evenement) {
            this.setEvenement(evenement);
            const inscriptions = await subscriptionService.getMySubscriptionsByEvenementId(evenementId);
            if (inscriptions) {
                this.setAlreadySubscribedPersons(inscriptions.map((x) => x.inscritId));
            }
        }
        this.setSubscription({
            inscriptionId: 0,
            createdById: "",
            dejeuner: evenement?.hasDejeuner,
            email: "",
            evenementId: evenementId,
            inscriptionsSessionAtelier: [],
            inscritId: "",
            nom: "",
            petitDejeuner: evenement?.hasPetitDejeuner,
            diner: evenement?.hasDiner,
            questions: "",
            lienWebinaire: "",
            creneauRendezVousId: null,
            commentaires: "",
            sujetRendezVousId: null
        });
        this.setLoading(false);
    }

    @action async addAtelierToSubscription(payload: { sessionId: number, sessionAtelierId: number | null }) {
        const session =  this.getEvenement?.sessions.find(x => x.sessionId === payload.sessionId);
        if (session) {
            const sessionAtelierIds = session.sessionAteliers.map(x => x.sessionAtelierId);
            const newSubscriptionList =
                this.subscription?.inscriptionsSessionAtelier?.filter(
                    (x) => !sessionAtelierIds.some((y) => x.sessionAtelierId === y)
                ) ?? [];
            if (payload.sessionAtelierId) {
                const sessionAtelier = session.sessionAteliers.find(x => x.sessionAtelierId === payload.sessionAtelierId);
                if (sessionAtelier) {
                    const toAdd = {
                        inscriptionSessionAtelierId: 0,
                        sessionAtelier: sessionAtelier,
                        sessionAtelierId: payload.sessionAtelierId
                    } as IInscriptionSessionAtelier;
                    newSubscriptionList.push(toAdd);
                }
            }
            this.setAtelierCreneauSubscriptionList(newSubscriptionList);
        }
    }

    @action async updateParticipant(newValue: ICollaborator) {
        this.setParticipant(newValue);
        this.setInscritName(newValue.displayName);
    }

    @action async setSubscriptionRendezVousSujetId(sujetId: number) {
        if (this.subscription) {
            this.subscription.sujetRendezVousId = sujetId;
        }
    }

    @action async setSubscriptionRendezVousCommentaires(commentaires: string) {
        if (this.subscription) {
            this.subscription.commentaires = commentaires;
        }
    }

    @action async setRendezVousCreneau(creneauId: number) {
        if (this.subscription) {
            this.subscription.creneauRendezVousId = creneauId;
        }
    }

    @action async setPetitDej(value: boolean) {
        this.setBreakfast(value);
    }

    @action async setDej(value: boolean) {
        this.setLunch(value);
    }

    @action async setDiner(value: boolean) {
        this.setDinner(value);
    }

    @action async removeSubscription(payload: { inscriptionId: number, inscritId: string, inscritNom: string }): Promise<boolean> {
        const desinscriptionRdv: DesinscriptionRdvMafDto = {
            inscritId: payload.inscritId,
            inscritNom: payload.inscritNom
        } as DesinscriptionRdvMafDto;
        const deleted = await subscriptionService.unsubscribePresentiel(payload.inscriptionId, desinscriptionRdv);
        return deleted;
    }
}