<template>
    <img
        class="rdvmaf-icon"
        :class="{ info: icon === 'info' }"
        :src="svgUrl"
        :width="`${width}px`"
        :height="`${height}px`"
        @click="() => this.$emit('click')"
    />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

export type IconSuffix =
    | 'add'
    | 'add-green'
    | 'add-white'
    | 'alert'
    | 'alert-error'
    | 'alert-succes'
    | 'alert-warning'
    | 'alert-info'
    | 'arrow-right'
    | 'attestation'
    | 'badge'
    | 'camera'
    | 'arrow-right'
    | 'calendar'
    | 'cible'
    | 'chat'
    | 'clock'
    | 'close'
    | 'check-circle'
    | 'check-green'
    | 'check'
    | 'check-white'
    | 'chevron-back'
    | 'chevron-down'
    | 'chevron-left'
    | 'chevron-up'
    | 'chevron-white'
    | 'copy'
    | 'cross'
    | 'cross-red'
    | 'dinner'
    | 'document'
    | 'download'
    | 'edit'
    | 'edit-white'
    | 'hand-shake'
    | 'hand-shake-black'
    | 'info'
    | 'light'
    | 'mail'
    | 'map-pin'
    | 'people'
    | 'people-add'
    | 'person'
    | 'person-add'
    | 'play'
    | 'question'
    | 'RDV-personnalise'
    | 'refresh'
    | 'repas'
    | 'reported'
    | 'warning'
    | 'success-notification';

@Component
export default class RdvmafIcon extends Vue {
    @Prop({ required: true })
    private icon!: IconSuffix;
    @Prop({ required: false, default: () => 20 })
    private height!: number;
    @Prop({ required: false, default: () => 20 })
    private width!: number;

    get svgUrl(): string {
        return `${window.location.origin}/assets/rdvmaf-icon-component/${this.icon}.svg`;
    }
}
</script>

<style lang="scss" scoped>
.info {
    opacity: 0.5;
}
</style>
