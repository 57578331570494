<template>
    <img class="rdvmaf-intervenant-image" v-if="profilePicture" :src="profilePicture" />
</template>

<script lang="ts">
import { IIntervenant } from "@/models/intervenant";
import { imageUrl } from "@/models/file";
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class IntervenantBadge extends Vue {
    @Prop({ required: true })
    private intervenant!: IIntervenant;
    

    get profilePicture(): string | null {
        if (this.intervenant.fileId) {
            return imageUrl(this.intervenant.fileId, true);
        }
        return null;
    }
}
</script>

<style lang="scss">
.rdvmaf-intervenant-image {
    width: 74px;
    height: 74px;
    object-fit: cover;
    border-radius: 50%;
    margin: 5px;
    aspect-ratio: 19/9;
}
</style>