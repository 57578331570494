import { IStatusPublication, ISujetRendezVous, ITypeEvenement } from '@/models/referentials';
import axiosService, { isCallSucceeded } from '@/tools/axios-wrapper';

interface IReferentielsDto {
    typeEvenements: ITypeEvenement[];
    statusPublications: IStatusPublication[];
    sujetsRendezVous: ISujetRendezVous[];
}

class ReferentielsService {
    private readonly url: string;
    constructor(url: string = 'api/referentiel') {
        this.url = url;
    }

    public async getAll(): Promise<IReferentielsDto | null> {
        const apiResponse = await axiosService.get<IReferentielsDto>(this.url);
        if (apiResponse && isCallSucceeded(apiResponse.status)) {
            return apiResponse.data ?? null;
        }
        return null;
    }
}

const referentialService = new ReferentielsService();
export default referentialService;
