<template>
    <rdvmaf-popin :value="value" class="rdvmaf-confirm-popin" @input="onClose" width="20%" >
        <template v-slot:subtitle> {{confirmText}} </template>
        <template v-slot:content>
            <div class="buttons">
                <rdvmaf-button :title="confirmButtonText" class="confirm" @click="onConfirm" />
                <rdvmaf-button title="Annuler" class="go-back" @click="onClose" />
            </div>
        </template>
    </rdvmaf-popin>
</template>

<script lang="ts">
import { Prop, Component, Vue } from "vue-property-decorator";
import RdvmafPopin from '@/shared/rdvmaf-popin.vue';
import RdvmafButton from '@/shared/rdvmaf-button.vue';
import { vxm } from "@/store";


@Component({
    components: {
        RdvmafPopin,
        RdvmafButton
    }
})
export default class ConfirmUnsubscribePopin extends Vue {
    @Prop({ required: true })
    private value!: boolean;
    @Prop({ required: true })
    private inscritNom!: string;
    @Prop({ required: true })
    private evenementNom!: string;
    @Prop({ required: true })
    private evenementDate!: string;

    get confirmText() {
        return (vxm.app.isCurrentUserSociete 
        ? `Vous êtes sur le point de désinscrire ${this.inscritNom} ` 
        : `Vous êtes sur le point de vous désinscrire `)
        + `de l’événement “${this.evenementNom}" du ${this.evenementDate}.`;
    }

    get confirmButtonText() {
        return vxm.app.isCurrentUserSociete ? `Désinscrire` : `Me désinscrire`
    }

    public onClose() {
        this.$emit('input', false);
    }

    public onConfirm() {
        this.$emit('input', false);
        this.$emit('confirm');
    }
}
</script>

<style lang="scss">
@import '@/styles';
.rdvmaf-confirm-popin {
    .rdvmaf-popin-overlay {
        z-index: 1001;
    }
    .rdvmaf-popin-card {
        width: 30% !important;
        z-index: 1002;
        .subtitle {
            margin-top: 56;
            display: flex;
            justify-content: center;
            font-size: 14;
            line-height: 16.8px;
        }
    }
    .buttons {
        display: flex;
        justify-content: space-evenly;
        button {
            width: 25%;
            &.confirm {
                background: $c-primary;
                margin-right: 20px;
            }
            &.go-back {
                background: transparent;
                color: #000000;
                border: none;
                &:hover {
                    color: $c-primary !important;
                    background: transparent !important;
                    border: none !important;
                }
            }
        }
    }
}
</style>