<template>
    <div class="rdvmaf-checkbox" :class="`${ value ? 'checked' : '' } ${ disabled ? 'disabled' : '' }`" @click="onClick">
        <label>&#10003;</label>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class RdvmafCheckbox extends Vue {
    @Prop({ required: true })
    private value!: boolean;
    @Prop({ required: false, default: () => false })
    private disabled!: boolean;

    public onClick() {
        if (!this.disabled) {
            this.$emit('input', !this.value);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles';

.rdvmaf-checkbox {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    border: 1.5px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    cursor: pointer;
    &:not(.checked):not(.disabled):hover {
        background: $c-primary-transparent;
    }
    &.checked:not(.disabled) {
        background: $c-primary;
        border: 0;
    }
    label {
        color: white;
        font-size: large;
        margin: 0;
        cursor: pointer;
    }
    &.disabled {
        background: #E0E0E0;
        cursor: default;
        border: 1.5px solid #999999;
        label {
            cursor: default;
            color: #E0E0E0;
        }
    }
}
</style>