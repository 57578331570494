import { IAdresse } from "./adresse";
import { ICreneauRendezVous } from "./creneau-rendez-vous";
import { IFile } from "./file";
import { IIntervenant } from "./intervenant";
import { IStatusPublication, ITypeEvenement, ITypePublicCible } from "./referentials";
import { ISession } from "./session";

export interface IEvenementIntervenant {
    evenementId: number;
    intervenantId: number;

    intervenant: IIntervenant;
}

export interface IEvenement {
    evenementId: number;
    sujet: string;
    descriptif: string;
    dateEvenement: Date;
    dureeContenu: number | null;
    dureeQuestions: number | null;
    duree: number | null;
    dateFin: Date | null;
    programme: string | null;
    publicCible: string | null;
    statusPublicationId: number;
    typeEvenementId: number;
    typePublicCibleId: number;
    adresseId: number | null;
    imageDescriptionFileId: number | null;
    isReported: boolean;
    isWebinaire: boolean;
    isPresentiel: boolean;
    hasPetitDejeuner: boolean;
    hasDejeuner: boolean;
    hasDiner: boolean;
    debutPetitDejeuner: Date;
    debutDejeuner: Date;
    debutDiner: Date;
    isSessionSouscriptible: boolean | null;

    status: IStatusPublication;
    type: ITypeEvenement;
    typePublicCible: ITypePublicCible;
    adresse: IAdresse | null;
    imageDescriptionFile: IFile | null;
    evenementsIntervenants: IEvenementIntervenant[];
    sessions: ISession[];
    creneauxRendezVous: ICreneauRendezVous[];

    nombreInscrits?: number;
    isMultiDate: boolean;
    nombreJour: number;

    
}

export interface IevenementConfiguration{
    showIntervenants: boolean;
    showAdresse: boolean;
    showSessionsAtelier: boolean;
    showRendezVousPersonnalise: boolean;
    showRepas: boolean;
    showStepper: boolean;
    hasProgrammeImpose: boolean;
    hasMultiDate: boolean;
    isEvenementPartenaire: boolean;
}

export function getEvenemenConfiguration(evenement: IEvenement): IevenementConfiguration{
    const evenementConfiguration: IevenementConfiguration = {
        showIntervenants: evenement.evenementsIntervenants.length > 0,
        showAdresse: evenement.adresse !== null,
        showSessionsAtelier: evenement.sessions.length > 0,
        showRendezVousPersonnalise: evenement.creneauxRendezVous.length > 0,
        showRepas: evenement.hasPetitDejeuner || evenement.hasDejeuner || evenement.hasDiner,
        showStepper: evenement.sessions.length > 0 || evenement.creneauxRendezVous.length > 0 || (evenement.hasPetitDejeuner || evenement.hasDejeuner || evenement.hasDiner),
        hasProgrammeImpose: !evenement.isSessionSouscriptible,
        hasMultiDate: true,
        isEvenementPartenaire: true
    };
    return evenementConfiguration;
}