<template>
    <div class="rdvmaf-select">
        <select :disabled="disabled" @change="onChange($event)" :value="getValue">
            <option v-for="option in options" 
            :key="`select-option-${option.key}`" 
            :value="option.key">
                {{ option.display }}
            </option>
        </select>
        <div class="selected-options"  v-if="isMultiple">
            <div class="item" v-for="key in value" :key="`selected-${key}`">
                <p>{{ display(key) }}</p>
                <rdvmaf-icon icon="cross" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import RdvmafIcon from '@/shared/rdvmaf-icon.vue';
export interface ISelectOption {
    value: any;
    display: string;
    key: string | number;
}
@Component({
    components: {
        RdvmafIcon
    }
})
export default class RdvmafSelect extends Vue {
    @Prop({ required: true })
    private options!: ISelectOption[];
    @Prop({ required: false, default: () => false })
    private disabled!: boolean;
    @Prop({ required: true })
    private value!: string | number | (string | number)[] | undefined;

    public onChange(event: { target: { value: string | number } }) {
        const option = this.options.find(x => x.key === event.target.value);
        this.$emit('on-click', option);
    }

    public display(key: string | number | undefined): string | undefined {
        if (key) {
            return this.options.find(x => x.key === key)?.display;
        }
        return undefined;
    }

    public get getValue(): string | number {
        if (this.isMultiple) {
            return '';
        } else {
            return this.value as string | number;
        }
    }

    get isMultiple(): boolean {
        return Array.isArray(this.value);
    }
}
</script>

<style lang="scss">
@import '@/styles';
.rdvmaf-select {
    select {
        width: 100%;
        border: 1px solid #E0E0E0;
        font-family: $museo-sans;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        background: url(@/assets/icons/chevron-down.svg) no-repeat right;
        padding: 20px 16px;
        -moz-appearance:none;
        -webkit-appearance:none;
        appearance:none;
        cursor: pointer;
        option {
            font-family: $museo-sans;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
        }
        &:focus {
            outline: none;
        }
    }
    .selected-options {
        .item {
            width: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px;
            .rdvmaf-icon {
                width: 15px;
            }
        }
    }
}
</style>