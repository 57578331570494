import { IStatusPublication, ISujetRendezVous, ITypeEvenement } from '@/models/referentials';
import referentialService from '@/wapi/type-evenement-service';
import { action, createModule, mutation } from 'vuex-class-component';

const VuexModule = createModule({
    namespaced: 'referentials',
    strict: true
});

export class ReferentialStore extends VuexModule {
    private typesEvenement: ITypeEvenement[] = [];
    private statusPublication: IStatusPublication[] = [];
    private sujetsRendezVous: ISujetRendezVous[] = [];
    private loading: boolean = false;

    get getTypesEvenement() {
        return this.typesEvenement;
    }

    get getStatusPublication() {
        return this.statusPublication;
    }

    get getSujetsRendezVous() {
        return this.sujetsRendezVous;
    }

    get getLoading(): boolean {
        return this.loading;
    }

    @mutation
    private setTypesEvenement(typesEvenement: ITypeEvenement[]) {
        this.typesEvenement = typesEvenement;
    }

    @mutation
    private setStatusPublication(statusPublication: IStatusPublication[]) {
        this.statusPublication = statusPublication;
    }

    @mutation
    private setSujetsRendezVous(sujetsRendezVous: ISujetRendezVous[]) {
        this.sujetsRendezVous = sujetsRendezVous;
    }

    @mutation
    private setLoading(loading: boolean) {
        this.loading = loading;
    }

    @action
    public async initStore() {
        this.setLoading(true);
        const referentials = await referentialService.getAll();
        if (referentials) {
            this.setTypesEvenement(referentials.typeEvenements);
            this.setStatusPublication(referentials.statusPublications);
            this.setSujetsRendezVous(referentials.sujetsRendezVous);
        }
        this.setLoading(false);
    }
}
