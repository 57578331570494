<template>
    <div class="rdvmaf-input-text" :style="`${width ? 'width:' + width : '' }`" :class="hasErrorClass">
        <label class="input-label" v-if="label">{{ label }}</label>
        <input class="input-text" :type="type" :value="value" @input="updateValue($event.target.value)" :disabled="disabled" />
        <rdvmaf-icon v-if="hasError" icon="alert-error" width="30" height="30"/>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator';
import RdvmafIcon from './rdvmaf-icon.vue';

@Component({
    components: {        
        RdvmafIcon
    }
})
export default class RdvmafInputText extends Vue {
    @Prop( { required: false, default: () => null })
    private label!: string | null;

    @Prop( { required: false, default: () => false })
    private hasError!: boolean;

    @Prop( { required: false, default: () => "text" })
    private type!: string;

    @Prop( { required: true })
    private value!: string;

    @Prop({ required: false, default: () => false })
    private disabled!: boolean;

    @Prop({ required: false, default: () => "" })
    private width!: string;
    private hasErrorClass: string = '';


    @Watch('hasError')
    private hasErrorOnchange(newValue: boolean){
        this.hasErrorClass = newValue ? 'error' : '';
    }
    

    public updateValue(value: string) {
        this.$emit('input', value)
    }

}
</script>

<style lang="scss">
@import '@/styles';
.rdvmaf-input-text {
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-width: 2px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color: black;
    max-width: 300px;
    padding-left: 8px;
    margin: 10px;
    background: #F2F2F2;
    position: relative;
    &.error{
        background: rgba(222, 56, 49, 0.1);
    }

    .input-label {
        color: #828282;
        font: $museo-sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
    }
    .input-text {
        border-width: 0px;
        font-family: $museo-sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        @include media-breakpoint-down(sm) {
            line-height: 14px;
            font-size: 12px;
        }
        color: rgba(30, 30, 30, 1);
        background: none;
        &:focus-visible {
            outline: none;
        }
        &:disabled {
            color: rgba(30, 30, 30, 0.7);
        }
    }
    .rdvmaf-icon{
        position: absolute;
        top: 13px;
        right: 3px;
    }
}
</style>