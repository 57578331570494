<template>
    <div class="rdvmaf-badge">
        <label v-html="character" />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

export type BadgeType = 'check';

const BadgeCharacter = {
    check: "&#10003;"
}

@Component
export default class RdvmafBadge extends Vue {
    @Prop({ required: false, default: () => 'check' })
    private type!: BadgeType;

    get character(): string {
        const tuple = Object.entries(BadgeCharacter).find(x => x[0] === this.type);
        if (tuple) {
            return tuple[1];
        }
        return '';
    }

}
</script>

<style lang="scss">
@import '@/styles';

.rdvmaf-badge {
    width: 24px;
    height: 24px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $c-primary;
    label {
        color: white;
        font-size: large;
        margin: 0;
    }
}
</style>