<template>
    <div class="badge rounded-pill bg-white text-red">
        <i class="maficon-circle-xmark"></i>
        Annulé
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import RdvmafIcon from '@/shared/rdvmaf-icon.vue';

@Component({
    components: {
        RdvmafIcon
    }
})
export default class RdvmafAnnuleTag extends Vue { }
</script>

<style lang="scss">
@import '@/styles';
</style>