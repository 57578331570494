import { SearchCompteCollaborateursResponseDto } from '@/data-contracts/search-compte-collaborateurs-response-dto';
import { IResultDto } from '@/models/result-dto';
import axiosService, { isCallSucceeded } from '@/tools/axios-wrapper';
import axiosServiceNew from '@/tools/axios-wrapper-new';
import { ICollaborator } from '../models/user';

class CollaborateurService {
    private readonly url: string;
    constructor(url = 'api/collaborateur') {
        this.url = url;
    }

    public async addCollaborateur(collaborateur: ICollaborator): Promise<IResultDto> {
        const apiResponse = await axiosServiceNew.post<IResultDto, ICollaborator>(`${this.url}/add`, collaborateur);
        return apiResponse.data;
    }

    public async getCollaborateurList(compteId: number): Promise<IResultDto<ICollaborator[]>> {
        const apiResponse = await axiosServiceNew.get<IResultDto<ICollaborator[]>>(`${this.url}/{compteId}/comptecollaborateurs`, null, {
            compteId: `${compteId}`
        });
        return apiResponse.data;;
    }

    public async searchCollaborateurs(compteId: number): Promise<SearchCompteCollaborateursResponseDto> {
        const apiResponse = await axiosService.get<any>(`${this.url}/{compteId}/search-compte-collaborateurs`, null, {
            compteId: `${compteId}`
        });
        if (apiResponse && isCallSucceeded(apiResponse.status)) {
            return apiResponse.data ?? [];
        }
        return {success : false, compte: null, collaborateurs: [], isPersonneMorale : false} as SearchCompteCollaborateursResponseDto;
    }
}

const collaborateurService = new CollaborateurService();
export default collaborateurService;
