<template>
    <div class="rdvmaf-popin" v-if="value">
        <div class="rdvmaf-popin-overlay">

        </div>
        <div class="rdvmaf-popin-card">
            <div class="title">
                <div class="remove-div">
                    <rdvmaf-icon icon="cross" :height="30" :width="30" @click="onClose"/>
                </div>
                <div class="title-text">
                    <slot name="title">
                        <h1>
                            {{ title }}
                        </h1>
                    </slot>
                </div>
            </div>
            <div class="subtitle">
                <slot name="subtitle"></slot>
            </div>
            <div class="content">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import RdvmafIcon from './rdvmaf-icon.vue';

@Component({
    components: {
        RdvmafIcon
    }
})
export default class RdvmafPopin extends Vue {
    @Prop({ required: true })
    private value!: boolean;
    @Prop({ required: false, default: () => '' })
    private title!: string;

    public onClose() {
        this.$emit("input", false);
    }
}
</script>

<style lang="scss">
@import '@/styles';
body.blocked {
  overflow: hidden;
}
.rdvmaf-popin {
    display: flex;
    justify-content: center;
    .rdvmaf-popin-overlay,
    .rdvmaf-popin-card {
        position: fixed;
    }
    .rdvmaf-popin-overlay {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #111111;
        opacity: 0.5;
        z-index: 1000;
    }
    .rdvmaf-popin-card {
        overflow-y: auto;
        background: #ffffff;
        padding: 72px;
        opacity: 1;
        min-height: 25%;
        z-index: 1001;
        @include media-breakpoint-up(lg) {
            max-height: 90%;
            width: 75%;
            top: 5%;
            max-width: 1000px;
        }
        @include media-breakpoint-down(lg) {
            width: 100%;
            height: 100%;
            padding: 10px;
            top: 0;
        }
        .title {
            .remove-div {
                display: flex;
                justify-content: flex-end;
                height: 30px;
                :first-child {
                    cursor: pointer;
                }
            }
            .title-text {
                display: flex;
                justify-content: center;
                > h1 {
                    font-family: $ff-primary;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 36px;
                    line-height: 44px;
                    max-width: 70%;
                    text-align: center;
                }
            }
        }
        .subtitle {
            display: flex;
            font-size: 20px;
            justify-content: center;
        }
        .content {
            padding-bottom: 20px;
        }
    }
}
</style>