export interface IFileContent {
    fileContentId: number;
    content: string;
}

export interface IFile {
    fileId: number;
    fileContentId: number;
    path: string;
    mimeType: string;
    lastModificationDate: Date | null;
    size: number;

    fileContent?: IFileContent;
}

export function imageUrl(fileId: number | null, isProfilePicture: boolean = false): string | null {
    if (fileId) {
        return `${window.location.origin}/api/file/${fileId}`;
    }
    return isProfilePicture ? `${window.location.origin}/assets/images/profile-default.svg` : null;
}