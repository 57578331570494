import { IReplay } from '@/models/replay';
import axiosService, { isCallSucceeded } from '@/tools/axios-wrapper';

class ReplayService {
    private readonly url: string;
    constructor(url = 'api/replay') {
        this.url = url;
    }

    public async getReplays(): Promise<IReplay[]> {
        const apiResponse = await axiosService.get<IReplay[]>(`${this.url}/replays`);
        if (apiResponse && isCallSucceeded(apiResponse.status)) {
            return apiResponse.data ?? [];
        }
        return [];
    }
    
    public async getById(id: number): Promise<IReplay | null> {
        const apiResponse = await axiosService.get<IReplay>(`${this.url}/{id}`, null, {
            id: `${id}`
        });
        if (apiResponse && isCallSucceeded(apiResponse.status)) {
            return apiResponse.data ?? null;
        }
        return null;
    }
}

export const replayService = new ReplayService();
