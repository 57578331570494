<template>
    <div class="rdvmaf-spinner">
        <div class="spin"></div>
        <!-- <b-spinner label="Chargement" :style="`width: ${size}; height: ${size}`" :class="variant"></b-spinner> -->
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RdvmafSpinner extends Vue {
    @Prop({ required: false, default: () =>  '3rem' })
    private size!: string;
    @Prop({ required: false, default: () => 'primary' })
    private variant!: string;
}
</script>

<style lang="scss" scoped>
@import '@/styles';

@keyframes spinner {
        0% {
          transform: translate3d(-50%, -50%, 0) rotate(0deg);
        }
        100% {
          transform: translate3d(-50%, -50%, 0) rotate(360deg);
        }
      }
      .rdvmaf-spinner {
            display: flex;
            justify-content: center;
            width: fit-content;
            height: 40px;
            margin: 2px;
            .spin::before {
                animation: 1.5s linear infinite spinner;
                animation-play-state: inherit;
                border: solid 5px white;
                border-bottom-color: $c-primary;
                border-radius: 50%;
                content: "";
                height: 40px;
                width: 40px;
                position: absolute;
                transform: translate3d(-50%, -50%, 0);
                will-change: transform;
            }
      }
</style>
