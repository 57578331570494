<template>
    <div class="badge rounded-pill" :class="typeEvenement.code.toLowerCase()">
        {{ content }}
    </div>
</template>

<script lang="ts">
import { IEvenement } from '@/models/evenement';
import { ITypeEvenement } from '@/models/referentials';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class TypeEvenementTag extends Vue {
    @Prop({ required: true })
    private typeEvenement!: ITypeEvenement;
    @Prop({ required: false, default: () => null })
    private evenement!: IEvenement | null;

    get content(): string {
        if (this.evenement?.adresse?.ville) {
            const dgaPrefix = this.isDinerGrandesAgences ? 'soirée-': 'RDVMAF-';
            return dgaPrefix.toUpperCase() + this.evenement.adresse.ville;
        }
        return this.typeEvenement.description;
    }

    private get isDinerGrandesAgences(): boolean {
        return this.evenement?.type?.code === 'DGA';
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles';

.badge {
    &.rdv,
    &.dga,
    &.ral,
    &.sal {
        background: $color-red;
    }
    &.web {
        background: $color-green;
    }
    color: $color-white;
}
</style>
