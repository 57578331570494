export function convertTimeToString(date: Date): string {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    let minutesStr = `${minutes}`;
    if (minutesStr == '0') {
        minutesStr = '00';
    } else if (minutesStr.length < 2) {
        minutesStr = '0' + minutesStr
    }
    return `${hours}h${minutesStr}`;
}